/* eslint-disable global-require */

const home =
    {
        products: [
            {
                name: "Baby Dodói",
                src: require("../images/products/baby-dodoi/featured.png").default,
                href: "/produtos/baby-dodoi"
            },
            {
                name: "Chef Lis",
                src: require("../images/products/chef-lis/featured.png").default,
                href: "/produtos/chef-lis"
            },
            {
                name: "Lalá Peniquinho",
                src: require("../images/products/lala-peniquinho/featured.png").default,
                href: "/produtos/lala-peniquinho"
            },
            {
                name: "Lovie Dog Pintura",
                src: require("../images/products/lovie-dog-pintura/featured.png").default,
                href: "/produtos/lovie-dog-pintura"
            },
            {
                name: "Little Shark",
                src: require("../images/products/little-shark/featured.png").default,
                href: "/produtos/little-shark"
            }
        ],
        news: [
            {
                name: "Baby Dodói",
                src: require("../images/products/baby-dodoi/lamina.png").default,
                href: "/produtos/baby-dodoi"
            },
            {
                name: "Chef Lis",
                src: require("../images/products/chef-lis/lamina.png").default,
                href: "/produtos/chef-lis"
            },
            {
                name: "Lovie Dog Pintura",
                src: require("../images/products/lovie-dog-pintura/lamina.png").default,
                href: "/produtos/lovie-dog-pintura"
            },
        ]
    };

export default home
