import React, {useState, useEffect} from "react"
import "./style.scss"
import sliders from "../../theme/sliders";

const SliderShow = () => {
    const [animate, setAnimate] = useState(false)
    const [index, setIndex] = useState(0)
    const [realIndex, setRealIndex] = useState(0)
    const [fadein, setFadein] = useState(false)
    const [fadeout, setFadeout] = useState(false)

    const handle = (key) => {
        if(key === realIndex) return;

        /*sliderTimer = setTimeout(() => {
            realIndex+1 >= sliders.length ? handle(0) : handle(realIndex+1)
        }, 12000);*/

        setRealIndex(key);
        setAnimate(true);
        setFadein(false);
        setFadeout(true);
        setTimeout(() => {
            setIndex(key);
            setFadeout(false);
            setFadein(true);
        }, 1500);
        setTimeout(() => {
            setAnimate(false);
        }, 3000);
    }

    useEffect(
        () => {
            let timer1 = setTimeout(() =>
                realIndex+1 >= sliders.length ? handle(0) : handle(realIndex+1),
                12000);
            return () => {
                clearTimeout(timer1);
            };
        },
        [index, realIndex, handle]
    );

    return (
        <div className="container mx-auto px-4">
            <div className="slider-wrapper h-32 relative overflow-hidden rounded-2xl">
                <div className={`bg-1 absolute transform bg-primary ${animate?"animate":""}`}/>
                <div className={`bg-2 absolute transform bg-darkpurple ${animate?"animate":""}`}/>
                <div className={`bg-3 absolute transform bg-blue ${animate?"animate":""}`}/>
                <div className={`bg-4 absolute transform ${animate?"animate":""}`}/>
                <div className={`bg-5 absolute transform bg-darkpurple ${animate?"animate":""}`}/>
                {
                    sliders.map((data, key) => {
                        return(<a key={key.toString()} href={data.href} target={data.target} className={key === index?'':'hidden'}>
                            {
                                data.images.map((image, key) => {
                                    return (<img key={key.toString()} src={image.src} alt="" className={`${image.classes} ${key > 0 && fadein?'fade-in':''} ${key > 0 && fadeout?'fade-out':''}`} />)
                                })
                            }
                        </a>)
                    })
                }
            </div>
            <div className="flex justify-center mt-2">
                {
                    sliders.map((data, key) => {
                        return (<div key={key.toString()} onClick={() => handle(key)} className={`w-3 h-3 bg-darkpurple m-1 rounded-full cursor-pointer transform duration-500 ease-in-out hover:scale-125 ${key !== realIndex?'opacity-30':''}`}/>)
                    })
                }
            </div>
        </div>
    )
}

export default SliderShow