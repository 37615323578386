/* eslint-disable global-require */
/*
NOTICE: não utilize margin-top, utilize top
        classe especial delay, a animação leva 0.5 a mais para começar
*/
const sliders = [
    {
        "href": "/produtos/chef-lis",
        "target": "_self",
        "images":
            [
                {
                    src: require("../images/slides/chef-lis/bg.png").default,
                    classes: 'absolute object-cover h-full w-full z-0'
                },
                {
                    src: require("../images/slides/chef-lis/logo.png").default,
                    classes: 'absolute z-20 top-12 ml-5 lg:ml-48 w-44 sm:w-60 xl:w-auto'
                },
                {
                    src: require("../images/slides/chef-lis/splash.png").default,
                    classes: 'absolute z-20 top-72 lg:ml-16 xl:ml-1/5 delay hidden lg:block md:w-auto'
                },
                {
                    src: require("../images/slides/chef-lis/splash2.png").default,
                    classes: 'absolute z-20 top-72 delay hidden xl:block md:w-auto left-1/2 transform -translate-x-1/2'
                },
                {
                    src: require("../images/slides/chef-lis/product1.png").default,
                    classes: 'absolute z-20 right-0 lg:right-32 bottom-4'
                }
            ]
    },
    {
        "href": "/produtos/lovie-dog-pintura",
        "target": "_self",
        "images":
            [
                {
                    src: require("../images/slides/lovie-dog/bg.png").default,
                    classes: 'absolute object-cover h-full w-full z-0'
                },
                {
                    src: require("../images/slides/lovie-dog/logo.png").default,
                    classes: 'absolute z-20 top-12 ml-5 lg:ml-48 w-44 sm:w-60 xl:w-auto'
                },
                {
                    src: require("../images/slides/lovie-dog/splash.png").default,
                    classes: 'absolute z-20 top-72 delay hidden xl:block md:w-auto left-1/2 transform -translate-x-1/2'
                },
                {
                    src: require("../images/slides/lovie-dog/splash2.png").default,
                    classes: 'absolute z-20 top-72 ml-32 delay hidden xl:block md:w-auto'
                },
                {
                    src: require("../images/slides/lovie-dog/product1.png").default,
                    classes: 'absolute z-20 right-0 lg:right-96 bottom-8 w-52'
                }
            ]
    },
    {
        "href": "/produtos/lala-peniquinho",
        "target": "_self",
        "images":
            [
                {
                    src: require("../images/slides/lala-peniquinho/bg.png").default,
                    classes: 'absolute object-cover h-full w-full z-0'
                },
                {
                    src: require("../images/slides/lala-peniquinho/logo.png").default,
                    classes: 'absolute z-20 top-12 ml-5 lg:ml-48 w-44 sm:w-60 xl:w-auto'
                },
                {
                    src: require("../images/slides/lala-peniquinho/splash.png").default,
                    classes: 'absolute z-20 top-72 lg:ml-16 xl:ml-96 delay hidden lg:block md:w-auto'
                },
                {
                    src: require("../images/slides/lala-peniquinho/splash2.png").default,
                    classes: 'absolute z-20 top-72 ml-32 delay hidden xl:block w-60'
                },
                {
                    src: require("../images/slides/lala-peniquinho/product1.png").default,
                    classes: 'absolute z-20 right-0 lg:right-96 bottom-8 w-52 lg:w-1/5'
                }
            ]
    },
    {
        "href": "/produtos/baby-dodoi",
        "target": "_self",
        "images":
            [
                {
                    src: require("../images/slides/baby-dodoi/bg.png").default,
                    classes: 'absolute object-cover h-full w-full z-0'
                },
                {
                    src: require("../images/slides/baby-dodoi/logo.png").default,
                    classes: 'absolute z-20 top-12 ml-5 lg:ml-48 w-44 lg:w-52 sm:w-60 xl:w-72'
                },
                {
                    src: require("../images/slides/baby-dodoi/splash.png").default,
                    classes: 'absolute z-20 top-72 lg:ml-16 xl:ml-96 delay hidden lg:block md:w-auto'
                },
                {
                    src: require("../images/slides/baby-dodoi/splash2.png").default,
                    classes: 'absolute z-20 top-72 ml-32 delay hidden xl:block w-60'
                },
                {
                    src: require("../images/slides/baby-dodoi/product1.png").default,
                    classes: 'absolute z-20 right-0 lg:right-96 bottom-4 w-52 lg:w-1/5'
                }
            ]
    },



    /** OLD sliders for reference*/
    /*
    {
        "href": "/produtos/my-uni",
        "target": "_self",
        "images":
            [
                {
                    src: require("../images/slides/old/my-uni/bg.jpg").default,
                    classes: 'absolute object-cover h-full w-full z-0'
                },
                {
                    src: require("../images/slides/old/my-uni/logo.png").default,
                    classes: 'absolute z-20 top-12 ml-5 lg:ml-48 w-44 sm:w-60 xl:w-auto'
                },
                {
                    src: require("../images/slides/old/my-uni/splash.png").default,
                    classes: 'absolute z-20 top-72 lg:ml-16 xl:ml-96 delay hidden lg:block md:w-auto'
                },
                {
                    src: require("../images/slides/old/my-uni/splash2.png").default,
                    classes: 'absolute z-20 top-72 ml-32 delay hidden xl:block md:w-auto'
                },
                {
                    src: require("../images/slides/old/my-uni/product1.png").default,
                    classes: 'absolute z-20 right-0 lg:right-96 bottom-8 w-52 lg:w-1/5'
                }
            ]
    },
    {
        "href": "/produtos/lovie-dogs",
        "target": "_self",
        "images":
            [
                {
                    src: require("../images/slides/old/lovie-dogs/bg.png").default,
                    classes: 'absolute object-cover object-bottom h-full w-full z-0'
                },
                {
                    src: require("../images/slides/old/lovie-dogs/logo.png").default,
                    classes: 'absolute z-20 top-12 ml-5 lg:ml-48 w-60 xl:w-auto'
                },
                {
                    src: require("../images/slides/old/lovie-dogs/splash.png").default,
                    classes: 'absolute z-20 bottom-4 lg:ml-16 xl:ml-96 delay hidden md:block md:w-auto'
                },
                {
                    src: require("../images/slides/old/my-uni/splash2.png").default,
                    classes: 'absolute z-20 top-72 ml-32 delay hidden xl:block md:w-auto'
                },
                {
                    src: require("../images/slides/old/lovie-dogs/product1.png").default,
                    classes: 'w-96 absolute z-20 right-96 bottom-4 hidden lg:block md:w-80'
                },
                {
                    src: require("../images/slides/old/lovie-dogs/product2.png").default,
                    classes: 'w-96 absolute z-20 right-40 bottom-4 hidden md:block md:w-80'
                },
                {
                    src: require("../images/slides/old/lovie-dogs/product3.png").default,
                    classes: 'w-96 absolute z-20 right-0 bottom-5 md:block md:w-80'
                },
            ]
    },
    {
        "href": "/produtos/dino-history-explorer-dedoches",
        "target": "_self",
        "images":
            [
                {
                    src: require("../images/slides/old/dino-history-explorer-dedoches/bg.png").default,
                    classes: 'absolute object-cover h-full w-full z-0'
                },
                {
                    src: require("../images/slides/old/dino-history-explorer-dedoches/logo.png").default,
                    classes: 'absolute z-20 top-12 ml-5 lg:ml-48 w-44 sm:w-60 xl:w-auto'
                },
                {
                    src: require("../images/slides/old/dino-history-explorer-dedoches/splash.png").default,
                    classes: 'absolute z-20 top-72 lg:ml-16 xl:ml-96 delay hidden lg:block md:w-auto'
                },
                {
                    src: require("../images/slides/old/dino-history-explorer-dedoches/product1.png").default,
                    classes: 'absolute z-20 right-0 bottom-8 lg:bottom-32'
                },
                {
                    src: require("../images/slides/old/dino-history-explorer-dedoches/product2.png").default,
                    classes: 'absolute z-20 right-0 lg:right-96 bottom-8 w-52 lg:w-1/5 hidden lg:block'
                }
            ]
    },
    {
        "href": "/produtos/dino-history-species-dedoches",
        "target": "_self",
        "images":
            [
                {
                    src: require("../images/slides/old/dino-history-species-dedoches/bg.png").default,
                    classes: 'absolute object-cover h-full w-full z-0'
                },
                {
                    src: require("../images/slides/old/dino-history-species-dedoches/logo.png").default,
                    classes: 'absolute z-20 top-12 ml-5 lg:ml-48 w-44 sm:w-60 xl:w-auto'
                },
                {
                    src: require("../images/slides/old/dino-history-species-dedoches/splash.png").default,
                    classes: 'absolute z-20 top-72 lg:ml-16 xl:ml-96 delay hidden lg:block md:w-auto'
                },
                {
                    src: require("../images/slides/old/dino-history-species-dedoches/product1.png").default,
                    classes: 'absolute z-20 right-0 w-72 md:w-auto bottom-0'
                },
                {
                    src: require("../images/slides/old/dino-history-species-dedoches/product2.png").default,
                    classes: 'absolute z-20 right-0 lg:right-96 bottom-8 w-52 lg:w-1/5 hidden lg:block'
                }
            ]
    },
    {
        "href": "/produtos/lizzy",
        "target": "_self",
        "images":
            [
                {
                    src: require("../images/slides/old/lizzy/bg.png").default,
                    classes: 'absolute object-cover object-bottom h-full w-full z-0'
                },
                {
                    src: require("../images/slides/old/lizzy/logo.png").default,
                    classes: 'absolute z-20 top-12 ml-5 lg:ml-48 w-44 sm:w-60 xl:w-auto'
                },
                {
                    src: require("../images/slides/old/lizzy/splash.png").default,
                    classes: 'absolute z-20 top-72 lg:ml-16 xl:ml-96 delay hidden lg:block md:w-auto'
                },
                {
                    src: require("../images/slides/old/lizzy/product1.png").default,
                    classes: 'absolute z-20 right-0 lg:right-96 bottom-0 w-auto'
                }
            ]
    },
    {
        "href": "/produtos/love-babie-aninha",
        "target": "_self",
        "images":
            [
                {
                    src: require("../images/slides/old/love-babie-aninha/bg.png").default,
                    classes: 'absolute object-cover object-bottom h-full w-full z-0'
                },
                {
                    src: require("../images/slides/old/love-babie-aninha/logo.png").default,
                    classes: 'absolute z-20 top-12 ml-5 lg:ml-48 w-44 sm:w-60 xl:w-auto'
                },
                {
                    src: require("../images/slides/old/love-babie-aninha/splash.png").default,
                    classes: 'absolute z-20 top-72 lg:ml-16 xl:ml-96 delay hidden lg:block md:w-auto'
                },
                {
                    src: require("../images/slides/old/love-babie-aninha/product1.png").default,
                    classes: 'absolute z-20 right-0 lg:right-96 bottom-4 w-52 lg:w-1/6'
                }
            ]
    },
    {
        "href": "/produtos/love-babie-banheirinha",
        "target": "_self",
        "images":
            [
                {
                    src: require("../images/slides/old/love-babie-banheirinha/bg.png").default,
                    classes: 'absolute object-cover h-full w-full z-0'
                },
                {
                    src: require("../images/slides/old/love-babie-banheirinha/logo.png").default,
                    classes: 'absolute z-20 top-12 ml-48 lg:w-44 xl:w-auto'
                },
                {
                    src: require("../images/slides/old/love-babie-banheirinha/splash.png").default,
                    classes: 'absolute z-20 top-64 ml-24 delay hidden md:block md:w-64 xl:w-auto'
                },
                {
                    src: require("../images/slides/old/love-babie-banheirinha/product1.png").default,
                    classes: 'absolute z-20 right-10 bottom-8 w-52 lg:w-auto'
                },
                {
                    src: require("../images/slides/old/love-babie-banheirinha/product2.png").default,
                    classes: 'absolute z-20 right-10 bottom-4 mr-44 lg:mr-72 w-32 lg:w-auto'
                }
            ]
    },
    {
        "href": "/produtos/dinossauros-adventure",
        "target": "_self",
        "images":
            [
                {
                    src: require("../images/slides/old/dinossauros-adventure/bg.png").default,
                    classes: 'absolute object-cover h-full w-full z-0'
                },
                {
                    src: require("../images/slides/old/dinossauros-adventure/logo.png").default,
                    classes: 'absolute z-20 top-4 left-0 right-0 ml-auto mr-auto'
                },
                {
                    src: require("../images/slides/old/dinossauros-adventure/product2.png").default,
                    classes: 'w-96 absolute z-20 right-10 bottom-8 hidden md:block md:w-80'
                },
                {
                    src: require("../images/slides/old/dinossauros-adventure/product1.png").default,
                    classes: 'w-96 absolute z-20 left-0 right-0 ml-auto mr-auto bottom-8 md:hidden xl:block'
                },
                {
                    src: require("../images/slides/old/dinossauros-adventure/product3.png").default,
                    classes: 'w-96 absolute z-20 left-10 bottom-8 hidden md:block md:w-80'
                },
            ]
    }*/
];

export default sliders
