import * as React from "react"
import Main from "../components/Layout/Main";
import SliderShow from "../components/SliderShow";
import home from "../theme/home";
import "../styles/pages/index.scss"
import SeeAllProducts from "../components/SeeAllProducts";
import {Link} from "gatsby";
import Seo from "../components/Seo";
import Images from "../theme/images";
import DownloadPdf from "../components/DownloadPdf";

const IndexPage = () => {
    return (
        <Main>
            <Seo description="Conheça o site da MisterBrinque e confira todas as nossas novidades!"/>
            <div className="mt-5">
                <SliderShow/>
            </div>
            <SeeAllProducts/>

            <div className="play-more bg-primary w-full mt-6">
                <div className="container mx-auto px-4 text-center p-8 mt-12">
                    <p className="text-darkpurple font-bold text-7xl">Brinque
                        <span>
                          <span className="animate-ping absolute opacity-75 -mt-0.5 text-">+</span>
                          <span>+</span>
                        </span>
                    </p>
                    <p className="text-darkpurple font-bold text-3xl pt-6">Transforme suas brincadeiras em <br className="hidden lg:block"/><span className="text-pink">momentos divertidos</span>, memoráveis e <span className="text-darkblue text-3xl">coloridos!</span></p>
                </div>
            </div>

            <div className="container mx-auto px-4">
                <div className="mt-12">
                    <p className="text-darkpurple text-3xl font-bold">Novidades</p>
                    <p>Clique e saiba mais</p>
                </div>

                <div className="flex flex-wrap mt-12">
                    {
                        home.news.map((news, key) => {
                            return(
                                <Link to={news.href} key={key.toString()} className="w-1/3 p-2">
                                    <img loading="lazy" src={news.src} alt={news.name} className="m-auto shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out border-4 border-white rounded" />
                                </Link>
                            )
                        })
                    }
                </div>
            </div>

            <div className="container mx-auto px-4">
                <div className="my-20">
                    <p className="text-darkpurple text-3xl font-bold">Para compartilhar</p>
                    <div className="relative">
                        <a href="https://www.instagram.com/misterbrinque" target="_BLANK" rel="noreferrer" className="absolute w-full h-full top-0 left-0 z-50"/>
                        <iframe title="instagram" className="w-full iframe-instagram" scrolling="no" srcDoc='<div data-mc-src="933a2d00-5fe0-40a4-a85b-7d64ad094a4a#instagram"></div><script src="https://cdn2.woxo.tech/a.js#628a4a89fed7ab002f34ca13" async data-usrc></script>'></iframe>
                    </div>
                </div>
            </div>

            <div className="border-t-4 border-darkpurple bg-purple-custom">
                <div className="container mx-auto px-4">
                    <div className="flex flex-col-reverse md:flex-row">
                        <div className="flex-1">
                            <img loading="lazy" src={Images.folder} className="md:float-right" alt="" />
                        </div>
                        <div className="flex-1 pt-12 px-12 md:pt-10 lg:px-32">
                            <DownloadPdf sendCatalog/>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default IndexPage
