import home from "../../theme/home";
import {Link} from "gatsby";
import * as React from "react";

const SeeAllProducts = () => {
    return (
        <div className="container mx-auto mb-12">
            <div className="flex flex-wrap mt-6">
                {
                    home.products.map((product, key) => {
                        return(
                            <Link to={product.href} key={key.toString()} href={product.src} className={`p-5 w-1/2 md:w-1/4 xl:w-1/5 ${home.products.length - 1 === key?'hidden xl:inline-block':''}`}>
                                <img src={product.src} alt={product.name} className="m-auto shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out border-4 border-white rounded" />
                            </Link>
                        )
                    })
                }
            </div>
            <Link to="/produtos" className="block mt-3 text-center text-darkblue text-lg">Ver todos!</Link>
        </div>
    )
}

export default SeeAllProducts